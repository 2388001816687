
html {
  width: 100% !important;
  overflow-y: scroll; /* Show scrollbars */
  overflow-x: hidden;
}

form {
  margin-top: 0px;
}

.tabelaServico {
    height: 60px !important;
    overflow-y: scroll; /* Show scrollbars */
    overflow-x: hidden;
    padding-top: 5px;
}

.iConteudo{
  margin-top: 0px;
  margin-left: 20%;
  width: 78% !important;
  font-family: verdana;
}
.iContainer {
  padding-left: 10px;
  width: 98% !important;
}

.container {
  padding-top: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.dataEnvio {
  width: 160px;
  height: 40px;
  text-align: center;
}

.form-select {
  height: 40px;
  font-size: 16px;
}

.marg-top {
  margin-top: 7px;
}

.esconde {
  visibility: hidden;
  display: none;
}

.espaco {
  padding-top: 10px;
}

.pad-top0 {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.pad-top5 {
  padding-top: 5px;
}

.pad-top9 {
  margin-top: 3px;
  padding-top: 9px;
  padding-bottom: 0px;
}

.pad-top10 {
  padding-top: 10px;
}

.pad-top20 {
  padding-top: 20px;
}

.pad-top23 {
  margin-top: 23px;
}

.pad-top30 {
  margin-top: 30px;
}

.pad-bottom0 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.pad-bottom10 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 10px !important;
  padding-top: 0px !important;
  line-height: 75%;
}

.pad-left {
  padding-left: 10px;
}

.bot-add {
  width: 15px !important;
  height: 15px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  border-color: transparent;
  font-size: 95%;
  color: blue;
  background-color: transparent !important;
}

.arquivo {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}

.conteudo {
  padding-left: 0%;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #77a8a8;
    border-color: #77a8a8;
    border-radius: 5px;
}

.file-preview {
  margin: 0 10px;
}

.form-check {
  padding-top: 7px;
}

.linha {
  width: 100%;
  border: 1px solid;
  border-color: black;
}

.motivo {
  height: 80px;
}

@media screen and (min-width: 1024px) {
  .iConteudo {
    margin-left: 7% !important;
    padding-left: 0px;
    width: 92% !important;
    font-family: verdana;
  }

  .iContainer {
    padding-left: 150px !important;
    width: 99% !important;
  }

}

@media screen and (max-width: 600px){

}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}

.shadow2 {
  -webkit-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.61);
  box-shadow: 5px 5px 6px 0px rgba(0,0,0,0.61);
}

.react-confirm-pro__body {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61) !important;
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61) !important;
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61) !important;
  background-color: #d5e1df;
}

.keep-right {
 text-align: right !important;
 float: right;
}

.keep-center {
 text-align: center !important;
}

.top-fixed {
  position: fixed;
  right: 5px;
}

.areaItensConfirmados {
  width: 98%;
  padding-top: 15px;
  height: 100px !important;
  overflow-y: scroll; /* Show scrollbars */
  overflow-x: hidden;
}

.bottom-fixed {
  left: 0px;
  position: fixed;
  height: 30px;
  bottom: 0px;
  right: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}
