
.usuario-lista{
  margin-left: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: verdana;
}

.usuario-lista span{
  margin: 2em 0;
  font-weight: 600;
  font-size: 1.2em;
  font-family: verdana;
}

.new{
  float:right;
  margin-bottom: 1.5em;
  background-color: #83bf02;
  color: #FFF;
  border:0;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 6px;
  transition: ease-in 0.2s;
}

.new svg{
  margin-right: 5px;
}

.new:hover{
  background-color: #5fd204;
  transform: scale(1.1);
}

.conteudo{
  margin-left: 20%;
  width: 79%;
  font-family: verdana;
}
.container {
  padding-left: 10px;
}

@media screen and (min-width: 1024px) {
  .conteudo {
    margin-left: 5%;
    padding-left: 40px;
    width: 95%;
    font-family: verdana;
  }

  .container {
    padding-left: 135px !important;
  }

  #paciente {
    margin-left: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 600px){

  #paciente {
    margin-left: 0px;
    padding-left: 0px;
  }

}

.textoFiltro{
  margin-top: 10px;
  margin-bottom: 50px;
  margin-left: 25%;
  font-family: verdana;
  padding-top: 20px;
  height:35px;
  line-height:35px;
  padding:0 5px;
}

.butInclui {
  margin-top: 10px;
  margin-bottom: 50px;
  padding-top: 10px !important;
  color: black;
}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}
