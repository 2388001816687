@font-face {
  font-family: "Mark Pro";
  src: url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.eot");
  src: url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.svg#Mark Pro")format("svg");
}

html{
  font-family: "Mark Pro";
}

.txt{
  color: #b5b1b1 !important;
  font-family: "Mark Pro";
}

.txtIntro{
  color: #101010 !important;
  font-family: "Mark Pro";
}

.txtDescrIntro{
  color: #101010 !important;
  font-family: "Mark Pro";
}

.txtDescr{
  color: #b5b1b1 !important;
  font-family: "Mark Pro";
}


.css-79elbk {
  position: relative !important;
  margin: 0% !important;
}

#intro {
  position: relative !important;
  margin: 0% !important;
  background-color: rgb(162, 183, 190) !important;
  padding: 5% !important;
  width: 100% !important;
}

#about {
  position: relative !important;
  margin: 0% !important;
  background-color: #01092a !important;
  padding: 5% !important;
  width: 100% !important;
}

.bot {
  background-color: #77a8a8;
}

#statKey {
  color: #7d1f1f !important;
}

.txtService {
  color: #101010 !important;
}

.imageContainer {
  width: 130% !important;
  border-radius: 0px !important;
}

.fVSuLN {
  border-style:groove !important;
  border-width: 1px !important;
  padding-top: 0px !important;
}

.itnnHM {
  border-style:groove !important;
  border-width: 1px !important;
  padding-top: 0px !important;
}
