
html {
  width: 98% !important;
}
form {
  margin-top: 0px;
}

.iConteudo{
  margin-top: 0px;
  margin-left: 20%;
  width: 79%;
  font-family: verdana;
}
.iContainer {
  padding-left: 10px;
}

.idOS {
  width: 80px;
  text-align: center;
}

.esconde {
  visibility: hidden;
  display: none;
}

.espaco {
  padding-top: 10px;
}

.formataCampo {
  width: 160px;
  height: 40px;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .iConteudo {
    margin-left: 7%;
    padding-left: 0px;
    width: 95%;
    font-family: verdana;
  }

  .iContainer {
    padding-left: 150px !important;
  }

}

@media screen and (max-width: 600px){

}

.input-field {
  height: 90px !important;
}

input[type="text"] {
  height: 30px !important;
}
