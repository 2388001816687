
html {
  width: 98% !important;
  overflow-y: scroll; /* Show scrollbars */
}

form {
  margin-top: 0px;
}

.iConteudo{
  margin-top: 0px;
  margin-left: 20%;
  width: 79%;
  font-family: verdana;
}
.iContainer {
  padding-left: 10px;
}

.idOS {
  width: 80px;
  text-align: center;
}

.esconde {
  visibility: hidden;
  display: none;
}

.cliente {
  visibility: hidden;
  display: none;
}

.espaco {
  padding-top: 10px;
}

.pad-top0 {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.pad-top10 {
  margin-top: 10px !important;
  padding-top: 10px !important;
}

.pad-top20 {
  margin-top: 20px !important;
  padding-top: 20px !important;
}

@media screen and (min-width: 1024px) {
  .iConteudo {
    margin-left: 7%;
    padding-left: 0px;
    width: 95%;
    font-family: verdana;
  }

  .iContainer {
    padding-left: 150px !important;
  }

}

@media screen and (max-width: 600px){


}
