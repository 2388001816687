
html {
  width: 75% !important;
}

form {
  margin-top: 0px;
}

input[type="file"] {
    display: none;
}

input[type="text"] {
  height: 30px !important;
}

.input_field {
  height: 20px !important;
}

.input_field {
  height: 20px !important;
}

/* Hide scrollbars
@page {
  margin: 1cm;
  size: landscape;
}
*/

#logo-cg {
  width: 50% !important;
}

.area {
}

.dataEnvio {
  width: 160px;
  height: 40px;
  text-align: center;
}

.form-select {
  height: 40px;
  font-size: 16px;
}

.marg-top {
  margin-top: 7px;
}

.esconde {
  visibility: hidden;
  display: none;
}

.espaco {
  padding-top: 10px;
}

.negrito {
  font-weight: bold !important;
}
.pad-top0 {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.pad-top10 {
  padding-top: 10px;
}

.pad-top20 {
  margin-top: 20px;
}

.pad-top23 {
  margin-top: 23px;
}

.pad-top30 {
  margin-top: 30px;
}

.pad-left {
  padding-left: 10px;
}

.pad-bottom0 {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.arquivo {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #77a8a8;
    border-color: #77a8a8;
    border-radius: 5px;
}

.file-preview {
  margin: 0 10px;
}

.form-check {
  padding-top: 7px;
}

.linha {
  width: 100%;
  border: 2px solid;
  border-color: black;
}

.observacao-planejador {
  height: 200px;
}

.motivo {
  height: 80px;
}

@media screen and (min-width: 1024px) {
  .iConteudo {
    margin-left: 7% !important;
    padding-left: 0px;
    width: 92% !important;
    font-family: verdana;
  }

  .iContainer {
    padding-left: 150px !important;
    width: 99% !important;
  }

}

@media screen and (max-width: 600px){

}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}

.top-fixed {
  position: fixed;
  right: 5px;
}

.areaIC {
  width: 100%;
  height: auto !important;
  overflow: hidden; /* Hide scrollbars */
}
