
.container-center{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.login{
  background: #EAEAEC;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 12%;
}

.login-area{
  display: flex;
  justify-content: center;
  background-color: #b2b5c6 !important;
  width: 100%;
}

.login-area img{
  padding: 20px;
  width: 170px;
  height: 130px;
}

form{
  margin-top: 1.5em;
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 0.5em;
  color: #848795;
}

.login input{
  margin-bottom: 15px;
  height: 35px;
  border:0;
  border-radius: 7px;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 7px;
  background-color: #181c2e;
  color: #FFF;
  font-size: 1.3em;
}

.login a{
  margin: 1.5em 0;
  color: #000;
  cursor: pointer;
}

#logo-cg {
  width: 130px;
  height: auto !important;
}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}

.botAcessar {
  background-color: #77a8a8;
}
