
.area {
  padding-left: 50px !important;
}

.logoCG {
  width: 400px !important;
  padding-left: 25% !important;
}

.qrCode {
  width: 256px !important;
  height: auto !important;
}

.assinatura {
  padding-left: 50% !important;  
}
