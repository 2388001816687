
html {
  width: 98% !important;
}
form {
  margin-top: 0px;
}

.iConteudo{
  margin-top: 0px;
  margin-left: 20%;
  width: 78%;
  font-family: verdana;
}
.iContainer {
  padding-left: 10px;
  width: 98%;
}

.dataEnvio {
  width: 160px;
  height: 40px;
  text-align: center;
}
.form-select {
  height: 40px;
  font-size: 16px;
}

.marg-top {
  margin-top: 7px;
}

.esconde {
  visibility: hidden;
  display: none;
}

.espaco {
  padding-top: 10px;
}

.pad-top10 {
  margin-top: 10px;
}

.pad-top20 {
  margin-top: 20px;
}

.pad-top23 {
  margin-top: 23px;
}

.pad-top30 {
  margin-top: 30px;
}

.pad-left {
  padding-left: 10px;
}

.arquivo {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}

input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #77a8a8;
    border-color: #77a8a8;
    border-radius: 5px;
}

.file-preview {
  margin: 0 10px;
}

.form-check {
  padding-top: 7px;
}

.linha {
  width: 100%;
  border: 2px solid;
  border-color: black;
}

@media screen and (min-width: 1024px) {
  .iConteudo {
    margin-left: 7%;
    padding-left: 0px;
    width: 95%;
    font-family: verdana;
  }

  .iContainer {
    padding-left: 150px !important;
  }

}

@media screen and (max-width: 600px){

}

.shadow1 {
  -webkit-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  -moz-box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
  box-shadow: 10px 10px 13px 0px rgba(0,0,0,0.61);
}
