@font-face {
  font-family: "Mark Pro";
  src: url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.eot");
  src: url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/7200c6dd8ac604abe09f5159e53a40c0.svg#Mark Pro")format("svg");
}

html {
  font-family: "Mark Pro";
  background-color: rgb(162, 183, 190) !important;
}
  
#root {
  background-color: rgb(162, 183, 190) !important;
}

#head1 {
  color: #090808 !important;
}

.shadow2 {
  background-color: transparent !important;
  text-decoration: none;
  border-color: white !important;
  border-width: 2px !important;
  border-style: groove;
  border-radius: 10% 10% !important;
}

.css-1px6djp {
  color: transparent !important;
}

.dQgJyf {
  background-color: transparent !important;
}

.snNvR  {
  background-color: transparent !important;
}

#welcome {
  font-size: x-large !important;
  color: #090808 !important;
}

.headStyle {
    background-color: #01092a !important;
}